<template>
<v-container style="margin-top:45px;"> 
    <v-container v-if="!linked||1">
       
           
            <v-img src="img/n_silpo.png" max-width="100%" contain center></v-img>
           
            <v-card elevation="10" class="rounded-card" style="margin-bottom:25px;margin-top: 50px;border-radius: 15px;height:90px;padding-left:5px;padding-top: 15px;font-size: 22px;">
              <font style="font-family: 'Inter';
font-style: normal;
font-weight: 400;


color: #F07300;">Доступний номінал: </font>200грн.<br>
            <font style="font-family: 'Inter';
font-style: normal;
font-weight: 400;

text-decoration-line: underline;

color: #F07300;">Правила по використанню
           </font>
      </v-card>


      <!-- <div id ="footer_my"  @click="$router.push({ name: 'Main' })"></div> -->
      <div id ="exitico"  @click="$router.push({ name: 'Main' })"></div>
      <!-- <v-row fluid>
      <v-col cols="1"></v-col>
      <v-col cols="10">
      <p style="text-align: center;">
        <br>
              <b>{{$t('cert_txt')}}</b>
      </p>
      <p style="text-align: center;" v-html="$t('cert_del_txt')">
              
      </p>
      </v-col>
      <v-col cols="1"></v-col>
      </v-row>
      <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-btn rounded class="primary_button" @click="/*$emit('pushe', {header:'Повідомлення',text:'Наразі обмін балів недоступний'});*/withdraw_silpo()">{{$t('btn_order')}}</v-btn>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row> -->

      <v-img src="img/order_btn.png" max-width="100%" contain center @click="/*$emit('pushe', {header:'Повідомлення',text:'Наразі обмін балів недоступний'});*/withdraw_silpo()"></v-img>

      <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <p style="text-align: center; margin-bottom: 0;">
              <b>{{$t('ordered_cert')}}:</b>
            </p>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>


<!--       
     <v-container>
       <v-container v-for="(order, i) in orders" :key="i">
        <v-card  elevation="10">
          <v-row fluid>
            
              <v-col cols="5">
                  <p style="text-align: center;">
                <b>{{order['a_date']}}</b>
              </p>
              </v-col>
              <v-col cols="7">
                  <p style="text-align: center;">

                <b v-if="order['r_state']=='1'">{{order['code']}}</b><br>
                <b v-if="order['is_used']=='1'" style="color:red">Використаний</b>
                <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
              </p>
              </v-col>
            
          </v-row>
          <v-row fluid class="ma-0" style=""> 
            <v-col cols="12"><center>
            <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br><br>
            <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a>
            
            </center>
            </v-col>
            
            </v-row>
        </v-card>
      </v-container>

     </v-container> -->
<!-- 
     <v-row fluid>
        <v-col cols="12">
              <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
        v-model="cert_model"
        >
                      <v-expansion-panel
                      v-if="1||unused_certs.length>0"
                        >
                        
                          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>Доступні ({{ unused_certs.length }})</b></v-expansion-panel-header>
                          <v-expansion-panel-content style="padding:15px;" >
                            <span v-if="unused_certs.length==0" style="font-style: italic;text-align: center;">У вас відсутні невикористані сертифікати</span>
                            <v-card  elevation="10"  v-for="(order, i) in unused_certs" :key="i" style="margin-bottom: 10px;">
                              <v-row fluid>
                                
                                  <v-col cols="5">
                                      <p style="text-align: center;">
                                    <b>{{order['a_date']}}</b>
                                  </p>
                                  </v-col>
                                  <v-col cols="7">
                                      <p style="text-align: center;">

                                    <b v-if="order['r_state']=='1'">{{order['code']}}</b><br>
                                    <b v-if="order['is_used']=='1'" style="color:red">Використаний</b>
                                    <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
                                  </p>
                                  </v-col>
                                
                              </v-row>
                              <v-row fluid class="ma-0" style=""> 
                                <v-col cols="12"><center>
                                <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br><br>
                                <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a>
                                
                                </center>
                                </v-col>
                                
                                </v-row>
                            </v-card>  
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                       
                        <v-expansion-panel
                         v-if="1||used_certs.length>0"
                        >
                          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>Використані ({{ used_certs.length }})</b></v-expansion-panel-header>
                          <v-expansion-panel-content style="padding:0px;" >
                            <span v-if="used_certs.length==0" style="font-style: italic;text-align: center;">У вас відсутні використані сертифікати</span>
                            <v-card  elevation="10"  v-for="(order, i) in used_certs" :key="i" style="margin-bottom: 20px;">
                              <v-row fluid>
                                
                                  <v-col cols="5">
                                      <p style="text-align: center;">
                                    <b>{{order['a_date']}}</b>
                                  </p>
                                  </v-col>
                                  <v-col cols="7">
                                      <p style="text-align: center;">

                                    <b v-if="order['r_state']=='1'">{{order['code']}}</b><br>
                                    <b v-if="order['is_used']=='1'" style="color:red">Використаний</b>
                                    <b v-if="order['is_used']=='0'" style="color:green">Доступний</b>
                                  </p>
                                  </v-col>
                                
                              </v-row>
                              <v-row fluid class="ma-0" style=""> 
                                <v-col cols="12"><center>
                                <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'\', \'_blank\'); return false;'">Відкрити сертифікат</a><br><br>
                                <a :onclick="'window.open(\'https://iteam.org.ua/certnew/?hash='+order['hash']+'&id='+order['id']+'&download=true\', \'_blank\'); return false;'">Завантажити сертифікат</a>
                                
                                </center>
                                </v-col>
                                
                                </v-row>
                            </v-card>  
                          </v-expansion-panel-content>
                        </v-expansion-panel>
       </v-expansion-panels>
                        

      </v-col>
    </v-row> -->





          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>
<style lang="css" scoped>

#exitico{
  background-image: url('/img/ico_exit.png');
  width:48px;height:48px;
  position:absolute;top:5px;left:5px;
}
#footer_my{
  background-image: url('/img/footer.png');
  width:100%;height:auto;min-height: 93px;min-width: 300px;;
  position:fixed;bottom:0; z-index: 2;left:0;right:0;margin: auto;
}
</style>
<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config"; 
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    
    amount:null,
    personal_number: null,
    oppa_account:null,
    orders:[],
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    month_lang:{
       
        'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },


      accordion: 'inset',
    popout: false,
    inset: false,
    multiple: true,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    cert_model:[0]
    
  }),
  computed: {
    used_certs: function(){
      return this.orders.filter((i) => i.is_used == "1");
    },
    unused_certs: function(){
      return this.orders.filter((i) => i.is_used == "0");
    },
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.get_silpo();
  },
  methods:{
    withdraw_silpo: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "silpo_order_eps"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
          
         this.get_silpo();
         alert(this.$t('order_success'));
        }
        else if(resp.data['code']=='insuf')
        {
          alert(this.$t('order_insuf'));

        }
        else if(resp.data['code']=='later_cert')
        {
          alert(this.$t('later_cert'));

        }
        this.isLoading=false;
        
      });
      
    },
    get_silpo: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_silpo_eps" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        this.orders=resp.data;
         this.isLoading=false;
        
      });
      
    },
    
  }
};
</script>

<i18n>
{
  "ua":{
      "title": "Обмін балів",
      
      "cert_txt":"Натисни «Замовити», щоб отримати сертифікат номіналом 200 грн. ",
      "btn_order":"Замовити",
      "cert_del_txt":"Отриманий сертифікат покажи на касі у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.<br><br>Слідкуй за терміном дії сертифікату! Ми не зможемо продовжити його дію або повернути бали.",
      "order_success":"Сертифікат замовлено! Збережіть його на телефон або зробіть скріншот. У період тимчасової зупинки програми відкрити сертифікати через iTeam буде неможливо.",
      "order_insuf":"Недостатньо балів",
      "ordered_cert":"Замовлені сертифікати",
      "later_cert":"Замовлення сертифікатів Сільпо тимчасово недоступне. Будь ласка, скористайтесь іншим видом «Емоцій»"
      


      

  }
}
</i18n>